import React, { useState } from "react"
import { graphql } from "gatsby"
import { GraphQLClient, gql } from "graphql-request"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import useFormFields from "../../hooks/useFormFields"

const ContactPageTemplate = ({ data, pageContext }) => {
  const { page } = data
  const {
    siteMetadata: { wpgraphqlUrl },
  } = pageContext

  const [mailResult, setMailResult] = useState()

  const [emailData, handleEmailDataChange] = useFormFields({
    email: "",
    subject: "",
    message: "",
    firstName: "",
    lastName: "",
    phone: "",
  })

  const submitMail = async (event) => {
    event.preventDefault()

    const mutation = gql`
      mutation SEND_EMAIL(
        $to: String!
        $from: String!
        $subject: String!
        $body: String!
        $clientMutationId: String!
      ) {
        sendEmail(
          input: {
            to: $to
            from: $from
            subject: $subject
            body: $body
            clientMutationId: $clientMutationId
          }
        ) {
          origin
          sent
          message
        }
      }
    `

    const graphQLClient = new GraphQLClient(wpgraphqlUrl, {})

    const mailData = await graphQLClient.request(mutation, {
      from: `${emailData.firstName} ${emailData.lastName} <${emailData.email}>`,
      subject: "FindCanary Website Request",
      body: `${emailData.message}<br/>___<br/><strong>Name:</strong> ${emailData.firstName} ${emailData.lastName}<br/><strong>Mail:</strong> ${emailData.email}<br/><strong>Phone:</strong> ${emailData.phone}`,
      clientMutationId: "SendEmail00",
    })

    setMailResult(mailData)
  }

  return (
    <Layout>
      <Seo seoData={page.seo} uri={page.uri} />

      <section id="contact-page-header" className="dark-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <h1>Get in touch.</h1>
              <p>
                Change doesn’t come without questions or challenges, but through
                quality partnerships and a passion for technology, we help
                companies find success.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section id="contact-page-form-container">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <form onSubmit={submitMail}>
                <div className="row first_row">
                  <div className="col-sm-6 first_col">
                    <div className="form-group">
                      <label className="control-label" htmlFor="FormFirstName">
                        First Name{" "}
                        <span
                          aria-hidden="true"
                          role="presentation"
                          className="required-color"
                        >
                          *
                        </span>
                      </label>
                      <div>
                        <input
                          id="FormFirstName"
                          placeholder="Your First Name"
                          name="firstName"
                          value={emailData.firstName}
                          onChange={handleEmailDataChange}
                          required
                          type="text"
                          className="form-control"
                          aria-required="true"
                          aria-labelledby="fld_8768091Label"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 last_col">
                    <div className="form-group">
                      <label
                        id="FormLastNameLabel"
                        htmlFor="FormLastName"
                        className="control-label"
                      >
                        Last Name{" "}
                        <span
                          aria-hidden="true"
                          role="presentation"
                          className="required-color"
                        >
                          *
                        </span>
                      </label>
                      <input
                        name="lastName"
                        value={emailData.lastName}
                        onChange={handleEmailDataChange}
                        placeholder="Your Last Name"
                        required
                        type="text"
                        className="form-control"
                        id="FormLastName"
                        aria-required="true"
                        aria-labelledby="FormLastNameLabel"
                      />
                    </div>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-sm-6 first_col">
                    <div className="form-group">
                      <label
                        id="FormMailLabel"
                        htmlFor="FormMail"
                        className="control-label"
                      >
                        Email{" "}
                        <span
                          aria-hidden="true"
                          role="presentation"
                          className="required-color"
                        >
                          *
                        </span>
                      </label>
                      <input
                        name="email"
                        value={emailData.email}
                        onChange={handleEmailDataChange}
                        placeholder="Your Email"
                        required
                        type="email"
                        className=" form-control"
                        id="FormMail"
                        aria-required="true"
                        aria-labelledby="FormMailLabel"
                      />{" "}
                    </div>
                  </div>
                  <div className="col-sm-6 last_col">
                    <div className="form-group">
                      <label
                        id="FormPhoneLabel"
                        htmlFor="FormPhone"
                        className="control-label"
                      >
                        Number{" "}
                        <span
                          aria-hidden="true"
                          role="presentation"
                          className="required-color"
                        >
                          *
                        </span>
                      </label>
                      <input
                        name="phone"
                        value={emailData.phone}
                        onChange={handleEmailDataChange}
                        placeholder="Your Phone Number"
                        required
                        type="phone"
                        className="form-control"
                        id="FormPhone"
                        aria-required="true"
                        aria-labelledby="FormPhoneLabel"
                      />
                    </div>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-sm-12 single">
                    <div className="form-group">
                      <label
                        id="FormMessageLabel"
                        htmlFor="FormMessage"
                        className="control-label"
                      >
                        Send A Message{" "}
                        <span
                          aria-hidden="true"
                          role="presentation"
                          className="required-color"
                        >
                          *
                        </span>
                      </label>
                      <textarea
                        name="message"
                        value={emailData.message}
                        onChange={handleEmailDataChange}
                        className="form-control"
                        id="FormMessage"
                        rows={7}
                        placeholder="Your Message..."
                        required="required"
                        aria-labelledby="FormMessageLabel"
                      />
                    </div>
                  </div>
                </div>
                <div className="row last_row">
                  <div className="col-sm-12 single">
                    <div className="form-group">
                      <input
                        className="btn btn-cta btn-primary-cta"
                        type="submit"
                      />
                      <div className="after-input-arrow">›</div>
                    </div>
                  </div>
                </div>
              </form>
              <div className="row mt-4">
                {mailResult?.sendEmail ? (
                  mailResult.sendEmail.sent ? (
                    <p
                      className="display-4"
                      dangerouslySetInnerHTML={{
                        __html: mailResult?.sendEmail?.message,
                      }}
                    />
                  ) : (
                    <p className="h4">
                      Something went wrong, sorry. Write an email directly via
                      the mail at the bottom.
                    </p>
                  )
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ContactPageTemplate

export const query = graphql`
  query ContactPage($databaseId: Int!) {
    page: wpPage(databaseId: { eq: $databaseId }) {
      uri
      seo {
        ...SeoData
      }
    }
  }
`
